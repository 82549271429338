import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import Breadcrumbs from "../../components/breadcrumbs"
import Layout from "../../components/layout"
import 'quill/dist/quill.snow.css';
// import { useQuill } from 'react-quilljs';
// import { db } from "../../../utils/firebase";

const CMS = () => {
    // const { quill, quillRef } = useQuill();

    // React.useEffect(() => {
    //     if (quill) {
    //         db
    //             .collection("content")
    //             .doc("custom-manure-pumping")
    //             .get()
    //             .then(console.log)
    //             .catch(console.log)

    //         quill.clipboard.dangerouslyPasteHTML('<h1>React Hook for Quill!</h1>');
    //     }
    // }, [quill]);

    return (<Layout className="secondary-page" >
        {/* <Breadcrumbs currentItem={{ path: "/cms", title: "CMS" }} ></Breadcrumbs>
        <Container className="main-content" style={{ paddingBottom: 200 }}>
            <div style={{ height: 400 }}>
                <div ref={quillRef} />
            </div>
        </Container> */}
    </Layout >
    )
}

export default CMS